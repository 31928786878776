import React, { ReactNode, useEffect, useState } from 'react';
import Typography, {
  TypographyVariant,
} from '../../../components/typography/typography';
import Icon, { IconType } from '../../../components/icons/icon';
import IconButton from '../../../components/icons/icon-button';
import './input-section.scss';

interface InputSectionProps {
  sectionTitle: string;
  children: ReactNode;
  parentExpanded?: boolean | undefined;
  lastChange?: string;
}

function InputSection({
  sectionTitle,
  children,
  parentExpanded,
  lastChange,
}: InputSectionProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const iconType = isExpanded ? IconType.ExpandLess : IconType.ExpandMore;

  useEffect(() => {
    if (parentExpanded !== undefined) {
      setIsExpanded(parentExpanded);
    }
  }, [parentExpanded, lastChange]);

  return (
    <div style={{ width: '100%' }}>
      <IconButton
        onClick={() => setIsExpanded(!isExpanded)}
        className="sectionButton"
        style={{ paddingLeft: '0px' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10%',
          }}
        >
          <Icon className="primary-text-color" iconType={iconType} />
          <Typography
            variant={TypographyVariant.Subtitle1}
            className="font-weight-regular primary-text-color "
          >
            {sectionTitle}
          </Typography>
        </div>
      </IconButton>
      <div style={{ paddingLeft: '16px' }}>{isExpanded && children}</div>
    </div>
  );
}

export default InputSection;
