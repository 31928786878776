import { useMutation } from 'react-query';
import { EstimateInput } from '../models/EstimateInput';
import { getEstimateOutput, getInvestmentOutput } from '../services/HouseEstimator';
import { useContext } from 'react';
import {
  Severity,
  SnackbarContext,
} from '../../components/snackbar/snackbar-context';
import { InvestmentInput } from '../models/InvestmentInput';

export const useEstimateOutputMutation = () => {
  const { showMessage } = useContext(SnackbarContext);

  return useMutation((input: EstimateInput) => getEstimateOutput(input), {
    onError: (error: unknown) => {
      showMessage((error as Error).message, Severity.Error);
    },
  });
};

// TODO: move
export const useInvestmentOutputMutation = () => {
  const { showMessage } = useContext(SnackbarContext);

  return useMutation((input: InvestmentInput) => getInvestmentOutput(input), {
    onError: (error: unknown) => {
      showMessage((error as Error).message, Severity.Error);
    },
  });
};
