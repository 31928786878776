import React, { Fragment, Ref, forwardRef } from 'react';
import Typography, { TypographyVariant } from '../typography/typography';
import IconButton from '../icons/icon-button';
import Icon, { IconType } from '../icons/icon';
import Tooltip from '../tooltip/tooltip';

interface InfoIconProps {
  title?: string;
  body?: string;
  disabled?: boolean;
  className?: string;
}

function InfoIcon({ title, body, disabled, className }: InfoIconProps) {
  return (
    <Tooltip
      title={
        <Fragment>
          <Typography>{title}</Typography>
          <Typography variant={TypographyVariant.Subtitle2}>{body}</Typography>
        </Fragment>
      }
      disableHoverListener={disabled}
      className={className}
    >
      <div>
        <IconButton>
          <Icon
            className={
              disabled ? 'transparent-light-text-color' : 'light-text-color'
            }
            iconType={IconType.Info}
          />
        </IconButton>
      </div>
    </Tooltip>
  );
}

export default InfoIcon;
