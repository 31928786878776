import React, { CSSProperties } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material';
import './select.scss';

interface SelectProps {
  value: string;
  onChange: (value: string) => void;
  options: SelectOptionProps[];
  label: string;
  style?: CSSProperties;
}

export interface SelectOptionProps {
  value: any;
  label: string;
}

function Select(props: SelectProps) {
  const id = Math.random().toString(36).substring(2);

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value as string);
  };

  return (
    <FormControl style={props.style} className="primary-text-color">
      <InputLabel id={id}>{props.label}</InputLabel>
      <MuiSelect
        id={id}
        value={props.value}
        label="Year"
        onChange={handleChange}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
