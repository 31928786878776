import React, { CSSProperties } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckBox from '@mui/material/Checkbox';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  style?: CSSProperties;
  className?: string;
}

function Checkbox({
  label,
  checked,
  onChange,
  style,
  className,
}: CheckboxProps) {
  return (
    <FormControlLabel
      control={
        <MuiCheckBox
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={label}
      style={style}
      className={className}
    />
  );
}

export default Checkbox;
