import React, { RefObject, useEffect, useState } from 'react';

export function useHeightObserver(ref: RefObject<HTMLElement>): number {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    };

    const observer = new ResizeObserver(updateHeight);

    updateHeight(); // Update height immediately on mount

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup function to avoid memory leaks
    return () => {
      observer.disconnect();
    };
  }, [ref]); // Re-run effect if ref changes

  return height;
}

export function useWidthObserver(ref: RefObject<HTMLElement>): number {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    const observer = new ResizeObserver(updateWidth);

    updateWidth(); // Update width immediately on mount

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup function to avoid memory leaks
    return () => {
      observer.disconnect();
    };
  }, [ref]); // Re-run effect if ref changes

  return width;
}
