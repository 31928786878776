import React from 'react';
import Typography, {
  TypographyVariant,
} from '../../../components/typography/typography';
import Divider from '../../../components/divider/divider';

interface ChartHeaderProps {
  title: string;
  subtitle?: string;
}

function ChartHeader({ title, subtitle }: ChartHeaderProps) {
  return (
    <div>
      <Typography variant={TypographyVariant.H5} className="light-text-color">
        {title}
      </Typography>

      {subtitle && (
        <Typography
          variant={TypographyVariant.Subtitle1}
          className="light-text-color"
        >
          {subtitle}
        </Typography>
      )}

      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
    </div>
  );
}

export default ChartHeader;
