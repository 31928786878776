import React, { CSSProperties } from 'react';
import { CardContent as MuiCardContent } from '@mui/material';

interface CardContentProps {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

function CardContent(props: CardContentProps) {
  return <MuiCardContent {...props}>{props.children}</MuiCardContent>;
}

export default CardContent;
