import React from 'react';
import TextInput from '../../../components/text-input/text-input';
import Typography, {
  TypographyVariant,
} from '../../../components/typography/typography';
import Checkbox from '../../../components/checkbox/checkbox';

interface InvestmentComparisonProps {
  annualRoi: number;
  setAnnualRoi: (annualRoi: number) => void;
  showComparison: boolean;
  setShowComparison: (show: boolean) => void;
}

function InvestmentComparisonInput({
  annualRoi,
  setAnnualRoi,
  showComparison,
  setShowComparison,
}: InvestmentComparisonProps) {
  const handleRoiChange = (roiString: string) => {
    const roiNum: number = parseFloat(roiString);

    if (roiNum !== null) {
      setAnnualRoi(roiNum);
    }
  };

  return (
    <div style={{ marginTop: '2rem' }}>
      <Checkbox
        label="Compare to Traditional Investment"
        checked={showComparison}
        onChange={setShowComparison}
        style={{ marginTop: '1rem' }}
      />

      {showComparison && (
        <Typography
          variant={TypographyVariant.Subtitle2}
          style={{ padding: '1rem' }}
          className="light-text-color"
        >
          Creates a line showing the value if you had invested the expenses each
          year into a traditional investment.
        </Typography>
      )}

      <TextInput
        label="Annual ROI"
        defaultValue={annualRoi}
        onChange={(e) => handleRoiChange(e.target.value)}
        id="annual-roi"
        name="annual-roi"
        prefix="%"
        type="number"
        disabled={!showComparison}
      />
    </div>
  );
}

export default InvestmentComparisonInput;
