import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import './wasm_exec.js';
import './wasmTypes.d.ts';
import LoadingContainer from '../components/loading-container/loading-container';

async function loadWasm(): Promise<void> {
  const goWasm = new window.Go();
  const result = await WebAssembly.instantiateStreaming(
    fetch('house-estimator.wasm'),
    goWasm.importObject
  );
  goWasm.run(result.instance);
}

interface LoadWasmProps {
  children: ReactNode;
}

function LoadWasm({ children }: LoadWasmProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadWasm().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Fragment>{children}</Fragment>
    </LoadingContainer>
  );
}

export default LoadWasm;
