import React from 'react';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import './tooltip.scss';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#faf7f7",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default Tooltip;
