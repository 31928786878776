import React, { useMemo } from 'react';
import { LineProps } from '../components/line-chart/line-chart';

interface RentalIncomeBreakEven {
  breakEvenYear: number;
}

const useLineBreakEvenHook = (line: LineProps): RentalIncomeBreakEven => {
  const breakEvenYear: number = useMemo(() => {
    const breakEvenCoordinate = line.coordinates.find((coordinate, i) => {
      return coordinate.y >= 0 && coordinate.x !== 0;
    });

    return breakEvenCoordinate ? breakEvenCoordinate.x : -1;
  }, [line]);

  return { breakEvenYear };
};

export default useLineBreakEvenHook;
