import { EstimateInput } from '../api/models/EstimateInput';
import * as yup from 'yup';

export interface EstimateInputForm extends EstimateInput {}

export const estimateInputValidationSchema = yup.object().shape({
  salePrice: yup
    .number()
    .required()
    .min(0)
    .test(
      'is-sum',
      'Sale price must be equal to the sum of the loan amount and down payment',
      function (value) {
        const { loanAmount } = this.resolve(yup.ref('loanDetails')) as {
          loanAmount: number;
        };
        const { downPayment } = this.resolve(yup.ref('buyingCosts')) as {
          downPayment: number;
        };
        return value === loanAmount + downPayment;
      }
    ),
  inflationRate: yup.number().required().min(0),
  income: yup.number().required().min(0),
  hasRentalIncome: yup.boolean().required(),
  loanDetails: yup.object().shape({
    loanAmount: yup.number().required().min(0),
    loanInterestRate: yup.number().required().min(0),
    loanDurationYears: yup.number().required().min(0),
  }),
  buyingCosts: yup.object().shape({
    downPayment: yup.number().required().min(0),
    titleSearch: yup.number().required().min(0),
    appraisalFee: yup.number().required().min(0),
    inspectionFee: yup.number().required().min(0),
    recordingFee: yup.number().required().min(0),
    loanOriginationFeePercentOfLoanAmount: yup.number().required().min(0),
    surveyFee: yup.number().required().min(0),
    settlementFee: yup.number().required().min(0),
    titleInsurancePercentOfSalePrice: yup.number().required().min(0),
  }),
  expenses: yup.object().shape({
    propertyTaxRatePercentOfMarketValue: yup.number().required().min(0),
    homeInsurancePerYear: yup.number().required().min(0),
    repairsPerYearPercentOfMarketValue: yup.number().required().min(0),
    electricityPerMonth: yup.number().required().min(0),
    heatingPerMonth: yup.number().required().min(0),
    waterPerMonth: yup.number().required().min(0),
    internetPerMonth: yup.number().required().min(0),
    otherPerYear: yup.number().required().min(0),
    hoaFeesPerMonth: yup.number().required().min(0),
    garbagePerMonth: yup.number().required().min(0),
    sewagePerMonth: yup.number().required().min(0),
  }),
  gains: yup.object().shape({
    propertyAppreciationRate: yup.number().required().min(0),
  }),
  rent: yup.object().shape({
    rentPerMonth: yup.number().required().min(0),
    propertyManagementFeePercentOfRent: yup.number().required().min(0),
    vacancyPercentPerYear: yup.number().required().min(0),
    landlordInsurancePerYear: yup.number().required().min(0),
    rentAppreciationRate: yup.number().required().min(0),
  }),
  sellingCosts: yup.object().shape({
    realEstateAgentCommissionPercentOfMarketValue: yup
      .number()
      .required()
      .min(0),
    preSaleInspection: yup.number().required().min(0),
    staging: yup.number().required().min(0),
    settlementFee: yup.number().required().min(0),
  }),
});
