import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Home from './pages/home/Home';
import Dashboard2 from './pages/dashboard/dashboard2';
import BuyerDashboard from './pages/buyer-dashboard/buyer-dashboard';
import InvestorDashboard from './pages/investor-dashboard/investor-dashboard';
import { routes } from './routes/routes';
import Calculator from './pages/cost-guide/wasm-test';
import SignUpPage from './pages/sign-up/sign-up';
import ReactGA from 'react-ga';

function App() {
  return (
    <Router>
      <PageViewTracker />
      <Routes>
        {/* <Route path={routes.home} element={<Home />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard2 />} /> */}
        {/* <Route
          path={routes.investorDashboard}
          element={<InvestorDashboard />}
        />
        <Route path="/calc" element={<Calculator />} /> */}
        <Route path={routes.buyerDashboard} element={<BuyerDashboard />} />
        <Route path={routes.signup} element={<SignUpPage />} />
        <Route path="*" element={<Navigate to={routes.buyerDashboard} />} />
      </Routes>
    </Router>
  );
}

function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
}

export default App;
