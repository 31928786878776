import React, { CSSProperties } from 'react';
import Typography, { TypographyVariant } from '../typography/typography';
import Icon, { IconType } from '../icons/icon';
import InfoIcon from '../info-icon/info-icon';

interface DataCardProps {
  body: string;
  iconVariant: IconType;
  title: string;
  style?: CSSProperties;
  infoTitle?: string;
  infoMessage?: string;
  disabled?: boolean;
}

function DataCard({
  body,
  iconVariant,
  title,
  style,
  infoTitle,
  infoMessage,
  disabled,
}: DataCardProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      className={'light-thin-rounded-border'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0.25rem',
          }}
        >
          <Icon
            iconType={iconVariant}
            className={
              disabled ? 'transparent-light-text-color' : 'primary-color'
            }
          />

          <Typography
            variant={TypographyVariant.Subtitle1}
            style={{ paddingLeft: '0.5rem' }}
            className={
              disabled ? 'transparent-light-text-color' : 'light-text-color'
            }
          >
            {title}
          </Typography>
        </div>
        {infoMessage && (
          <InfoIcon disabled={disabled} title={infoTitle} body={infoMessage} />
        )}
      </div>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        }}
      >
        <Typography
          variant={TypographyVariant.H6}
          className={
            disabled ? 'transparent-light-text-color' : 'light-text-color'
          }
        >
          {body}
        </Typography>
      </div>
    </div>
  );
}

export default DataCard;
