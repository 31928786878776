import React, { Ref, forwardRef } from 'react';
import AppBar from '../../components/app-bar/app-bar';
import Box from '../../components/box/box';
import Toolbar from '../../components/toolbar/toolbar';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Button from '../../components/button/button';
import IconButton from '../../components/icons/icon-button';
import Grid from '../../components/grid/grid';
import Icon, { IconType } from '../../components/icons/icon';
import { routes } from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import './top-nav-bar.scss';
import { costGuideUrl, landingPageUrl } from '../../constants';

interface TopNavBarProps {
  setMaxWidth?: boolean;
}

const TopNavBar = forwardRef(function TopNavBar(
  props: TopNavBarProps,
  ref?: Ref<HTMLInputElement>
) {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('..' + routes.signup);
  };

  const handleHomeClick = () => {
    window.location.href = landingPageUrl;
  };

  const handleDashboardClick = () => {
    navigate('..' + routes.buyerDashboard);
  };

  const handleCostGuideClick = () => {
    window.location.href = costGuideUrl;
  };

  return (
    <>
      {/* <Grid
        item
        xs={12}
        style={{ flex: 0, height: '30px' }}
        className="dark1-background-color"
      >
        Test
      </Grid> */}
      <Grid
        ref={ref}
        item
        xs={12}
        style={{
          flex: 0,
          borderBottom: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'center',
        }}
        className="dark3-background-color"
      >
        <Box
          style={{ flexGrow: 1, maxWidth: props.setMaxWidth ? '1400px' : '' }}
        >
          <AppBar
            position="static"
            className="dark3-background-color primary-color-icon"
            style={{
              boxShadow: 'none',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
          >
            <Toolbar>
              {/* logo  */}
              <IconButton
                size="small"
                edge="start"
                style={{ marginRight: '4px' }}
              >
                <Icon
                  iconType={IconType.HouseCircleCheck}
                  style={{ height: '28px', width: '28px' }}
                />
              </IconButton>
              <Typography
                variant={TypographyVariant.Subtitle1}
                style={{ flexGrow: 1, cursor: 'pointer', fontSize: '1.5rem' }}
                onClick={handleHomeClick}
                className="text3-color"
              >
                <div className="brand-name-text">
                  <p className="light-text-color">PROP COST</p>
                </div>
              </Typography>

              {/* other tabs  */}
              {/* <Button
                style={{ marginRight: '8px' }}
                variant="text"
                color="secondary"
              >
                About
              </Button> */}
              <Button
                variant="text"
                style={{ marginRight: '8px' }}
                color="secondary"
                onClick={handleCostGuideClick}
              >
                Cost Guide
              </Button>
              <Button
                variant="text"
                style={{ marginRight: '8px' }}
                color="secondary"
                onClick={handleDashboardClick}
              >
                Dashboard
              </Button>

              {/* sign up  */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignUpClick}
              >
                Sign Up
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      </Grid>
    </>
  );
});

export default TopNavBar;
