import React, { CSSProperties, Ref, forwardRef } from 'react';
import { Grid as MuiGrid } from '@mui/material';

interface GridProps {
  container?: boolean;
  item?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  height?: number | string;
  width?: number | string;
  className?: string;
  align?: string;
}

const Grid = forwardRef(function Grid(
  props: GridProps,
  ref?: Ref<HTMLDivElement>
) {
  return <MuiGrid {...props} ref={ref} />;
});

export default Grid;
