import { createTheme } from '@mui/material/styles';

// dark theme
// export const colors = {
//   Primary: '#0e7ea4',
//   Secondary: 'rgb(246, 247, 249)',
// };

// light theme
export const colors = {
  Primary: '#0f6bff', // highlight color in index.scss
  Secondary: '#0b3558', // lightTextColor in index.scss
  Dark1Color: '#f4f8ff', // dark1Color in index.scss
};

// Define your palette colors
const palette = {
  primary: {
    main: colors.Primary,
  },
  secondary: {
    main: colors.Secondary,
  },
};

// Create a theme instance
const theme = createTheme({
  palette,
});

export default theme;
