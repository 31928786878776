import React, { CSSProperties, ReactNode, Ref, forwardRef } from 'react';
import { Paper as MuiPaper } from '@mui/material';

interface PaperProps {
  elevation?: number;
  style?: CSSProperties;
  children?: ReactNode;
  className?: string;
}

const Paper = forwardRef(function Paper(
  props: PaperProps,
  ref?: Ref<HTMLDivElement>
) {
  return (
    <MuiPaper {...props} ref={ref}>
      {props.children}
    </MuiPaper>
  );
});

export default Paper;
