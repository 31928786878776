import { useQuery } from 'react-query';
import { getBuyerEstimateInput, getInvestorEstimateInput } from '../services/HouseEstimator';
import queryKeys from '../Keys';
import { useContext } from 'react';
import {
  Severity,
  SnackbarContext,
} from '../../components/snackbar/snackbar-context';

export const useBuyerEstimateInputQuery = () => {
  const { showMessage } = useContext(SnackbarContext);

  return useQuery(queryKeys.getBuyerEstimateInput(), () => getBuyerEstimateInput(), {
    onError: (error: unknown) => {
      showMessage((error as Error).message, Severity.Error);
    },
  });
};

export const useInvestorEstimateInputQuery = () => {
  const { showMessage } = useContext(SnackbarContext);

  return useQuery(queryKeys.getInvestorEstimateInput(), () => getInvestorEstimateInput(), {
    onError: (error: unknown) => {
      showMessage((error as Error).message, Severity.Error);
    },
  });
};
