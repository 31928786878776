export const ChartPurple = '#8884d8';
export const ChartGreen = '#82ca9d';
export const ChartRed = '#ff7f7f';
export const ChartBlue = '#87ceeb';
export const ChartOrange = '#d88a4a';
export const ChartYellow = '#d8d84a';
export const ChartPink = '#f18fb5';
export const ChartGrey = '#a1a1a1';
export const ChartTeal = '#4ad8d8';
export const ChartLime = '#8ad84a';
export const ChartMagenta = '#d84ad8';

export const ChartColors = [
  ChartPurple,
  ChartGreen,
  ChartRed,
  ChartBlue,
  ChartOrange,
  ChartYellow,
  ChartPink,
  ChartGrey,
  ChartTeal,
  ChartLime,
  ChartMagenta,
];

// function to return a number of colors
export function getColors(num: number): string[] {
  if (num <= 0) {
    return [];
  }

  if (num < ChartColors.length) {
    return ChartColors.slice(0, num);
  } else {
    return ChartColors;
  }
}
