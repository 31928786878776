import React, { CSSProperties, ReactNode } from 'react';
import { CardHeader as MuiCardHeader } from '@mui/material';

interface CardHeaderProps {
  title?: string;
  action?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

function CardHeader(props: CardHeaderProps) {
  return <MuiCardHeader {...props} />;
}

export default CardHeader;
