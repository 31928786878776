import React, { useMemo, useRef } from 'react';
import Paper from '../../components/paper/paper';
import CarouselStepper from '../../components/carousel-stepper/carousel-stepper';
import { EstimateOutput } from '../../api/models/EstimateOutput';
import YearlyExpenseGainChart from './charts/yearly-expense-gain-chart';
import YearlyExpensePieChart from './charts/yearly-expense-pie-chart';
import CumulativeGainLossChart from './charts/cumulative-gain-loss-chart';
import SaleChart from './charts/sale-chart';
import { useHeightObserver } from '../../utils/resize-observer-hooks';
import YearlyGainPieChart from './charts/yearly-gain-pie-chart';
import MarketValueChart from './charts/market-value-chart';
import { DashboardViewType } from './dashboard';
import ContributionChart from './charts/contribution-chart';
import OutOfPocketChart from './charts/out-of-pocket-chart';
import RoiChart from './charts/roi-chart';
import { SaleChartDetailed } from './charts/sale-chart-detailed';
import OverviewChart from './charts/overview-chart';
import TabBar from '../../components/tab-bar/tab-bar';

interface EstimateResultsProps {
  estimateOutput: EstimateOutput;
  viewType: DashboardViewType;
}

function EstimateResults({ estimateOutput, viewType }: EstimateResultsProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  // heights
  const containerRef = useRef(null);
  const stepperRef = useRef(null);
  const containerHeight = useHeightObserver(containerRef);
  const stepperHeight = useHeightObserver(stepperRef);
  const activeChartHeight = containerHeight - stepperHeight;

  // charts
  const buyerChartComponents = useMemo(
    () => [
      <OverviewChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        setActiveStep={setActiveStep}
      />,
      <YearlyExpenseGainChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <YearlyExpensePieChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <YearlyGainPieChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <SaleChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        viewType={viewType}
      />,
      <CumulativeGainLossChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        viewType={viewType}
      />,
      <MarketValueChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
    ],
    [activeChartHeight, estimateOutput, viewType]
  );

  const investorChartComponents = useMemo(
    () => [
      <SaleChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        viewType={viewType}
      />,
      // <RoiChart height={activeChartHeight} estimateOutput={estimateOutput} />,
      <OutOfPocketChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        viewType={viewType}
      />,
      <YearlyExpensePieChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <YearlyGainPieChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <YearlyExpenseGainChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
      <CumulativeGainLossChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
        viewType={viewType}
      />,
      <MarketValueChart
        height={activeChartHeight}
        estimateOutput={estimateOutput}
      />,
    ],
    [activeChartHeight, estimateOutput, viewType]
  );

  const chartComponents =
    viewType === DashboardViewType.Investor
      ? investorChartComponents
      : buyerChartComponents;

  const numCharts = chartComponents.length;

  // active chart
  const activeChart = useMemo(() => {
    if (activeStep >= 0 && activeStep < chartComponents.length) {
      return chartComponents[activeStep];
    } else {
      return <div>Error</div>;
    }
  }, [activeStep, chartComponents]);

  return (
    <Paper
      elevation={3}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid rgba(255, 255, 255, 0.2)',
      }}
      ref={containerRef}
      className="dark3-background-color"
    >
      <TabBar
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        tabs={[
          'Overview',
          'Monthly Costs',
          'Expenses',
          'Returns',
          'Profit at Sale',
          'Gain/Loss',
          'Property Value',
        ]}
        ref={stepperRef}
      />

      {/* <CarouselStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        numSteps={numCharts}
        ref={stepperRef}
        className="dark3-background-color"
      /> */}

      {activeChart}
    </Paper>
  );
}

export default EstimateResults;
