import React, { CSSProperties } from 'react';
import Typography, { TypographyVariant } from '../typography/typography';
import Icon, { IconType } from '../icons/icon';
import InfoIcon from '../info-icon/info-icon';
import './data-card.scss';

interface DataTileProps {
  iconVariant: IconType;
  title: string;
  subtitle?: string;
  description?: string;
  actionText?: string;
  backgroundColor?: string;
  iconColor?: string;
  iconBackgroundColor?: string;
  onClickActionText?: () => void;
  style?: CSSProperties;
  className?: string;
}

function DataTile({
  iconVariant,
  title,
  subtitle,
  description,
  actionText,
  backgroundColor,
  iconColor,
  iconBackgroundColor,
  onClickActionText,
  style,
  className,
}: DataTileProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: backgroundColor,
        padding: '1rem',
        flexGrow: 1,
        justifyContent: 'space-between',
        ...style,
      }}
      className={`default-border ${className}`}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Icon
          iconType={iconVariant}
          style={{
            color: iconColor,
            backgroundColor: iconBackgroundColor,
            padding: '0.5rem',
            borderRadius: '50%',
          }}
        />

        <Typography
          variant={TypographyVariant.H6}
          style={{ marginTop: '1rem' }}
        >
          {title}
        </Typography>

        {subtitle && (
          <Typography variant={TypographyVariant.Subtitle1}>
            {subtitle}
          </Typography>
        )}

        {description && (
          <Typography
            variant={TypographyVariant.Subtitle1}
            style={{ marginTop: '1rem' }}
          >
            {description}
          </Typography>
        )}
      </div>

      <div>
        {actionText && (
          <Typography
            variant={TypographyVariant.Subtitle2}
            style={{
              marginTop: '1rem',
              width: '100%',
              textAlign: 'end',
            }}
            className="action-text"
            onClick={onClickActionText}
          >
            {actionText}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default DataTile;
