import React, { useMemo } from 'react';
import {
  CoordinateProps,
  LineProps,
} from '../components/line-chart/line-chart';
import useInflatedValuesHook from './inflated-values-hook';
import { ChartOrange } from './constants/color';

interface RentLineResults {
  breakEvenYear: number;
  rentLine: LineProps;
}

const useRentLineHook = (
  monthlyRent: number,
  inflationRate: number,
  numberOfYears: number,
  comparisonLine: LineProps
): RentLineResults => {
  const rentValues = useInflatedValuesHook(
    monthlyRent,
    inflationRate,
    numberOfYears
  );

  const rentLine: LineProps = useMemo(() => {
    let cumulatedRent = 0;

    const coordinates = rentValues.map((value) => {
      cumulatedRent += value.inflatedValue * 12;
      return { x: value.year, y: cumulatedRent * -1 };
    });

    return {
      label: 'Rent',
      key: 'rent',
      color: ChartOrange,
      coordinates,
    };
  }, [rentValues]);

  const breakEvenYear: number = useMemo(() => {
    const breakEvenCoordinate = rentLine.coordinates.find((coordinate, i) => {
      const comparisonCoordinate = comparisonLine.coordinates.find(
        (c) => c.x === coordinate.x
      );
      return (
        comparisonCoordinate !== undefined &&
        coordinate.y < comparisonCoordinate.y
      );
    });

    return breakEvenCoordinate ? breakEvenCoordinate.x : -1;
  }, [rentLine, comparisonLine]);

  return { breakEvenYear, rentLine };
};

export default useRentLineHook;
