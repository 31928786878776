import React, { CSSProperties } from 'react';
import { Box as MuiBox } from '@mui/material';

interface BoxProps {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

function Box(props: BoxProps) {
  return <MuiBox {...props}>{props.children}</MuiBox>;
}

export default Box;
