import React, { useMemo, useState } from 'react';
import LineChart, {
  CoordinateProps,
  LineProps,
} from '../../../components/line-chart/line-chart';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import { ChartPurple } from '../../../utils/constants/color';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import useRentLineHook from '../../../utils/rent-line-hook';
import RentComparisonInput from './rent-comparison-input';
import useLineBreakEvenHook from '../../../utils/rental-income-break-even-hook';
import DataCard from '../../../components/data-card/data-card';
import { IconType } from '../../../components/icons/icon';
import { DashboardViewType } from '../dashboard';
import Checkbox from '../../../components/checkbox/checkbox';
import InvestmentComparisonInput from './InvestmentComparisonInput';
import { useInvestmentLineHook } from '../../../utils/investment-line-hook';
import { SaleChartTable } from './sale-chart-detailed';

interface SaleChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
  viewType: DashboardViewType;
}

function SaleChart({ estimateOutput, height, viewType }: SaleChartProps) {
  const [monthlyRent, setMonthlyRent] = useState(1300);
  const [inflationRate, setInflationRate] = useState(3);
  const [annualRoi, setAnnualRoi] = useState(5);
  const [showComparison, setShowComparison] = useState(false);
  const [showTableView, setShowTableView] = useState(false);
  const [compareRent, setCompareRent] = useState(false);

  // net line
  const netLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        continue;
      }

      coords.push({ x: year.year, y: year.sale.netProfit });
    }

    return {
      label: 'Net Profit',
      key: 'netProfit',
      color: ChartPurple,
      coordinates: coords,
    };
  }, [estimateOutput]);

  // rent line
  const { breakEvenYear, rentLine } = useRentLineHook(
    monthlyRent,
    inflationRate,
    netLine.coordinates.length,
    netLine
  );

  // rent break even point
  const rentalIncomeBreakEvenPoint: number =
    useLineBreakEvenHook(netLine).breakEvenYear;

  // investment line
  const investmentLine: LineProps = useInvestmentLineHook(
    estimateOutput,
    annualRoi
  );

  // lines
  const buyerLines = compareRent ? [rentLine, netLine] : [netLine];
  const investorLines = showComparison ? [investmentLine, netLine] : [netLine];
  const lines: LineProps[] =
    viewType === DashboardViewType.Buyer ? buyerLines : investorLines;

  // break even year modifier
  const breakEvenYearDisplay = (year: number): string => {
    if (year < 0) {
      return 'Never';
    } else {
      return year.toString();
    }
  };

  return (
    <Box
      style={{
        padding: '1rem',
        height: `calc(${height}px - 1rem)`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChartHeader
        title="Net Profit at Sale"
        subtitle="A chart that shows the net profit or loss from selling the house each year."
      />

      <Grid
        container
        style={{
          flexGrow: 1,
          overflow: 'hidden',
          height: '100%',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            paddingRight: '1rem',
            overflow: 'auto',
            display: 'flex',
            height: '100%',
          }}
        >
          {viewType === DashboardViewType.Investor ? (
            <>
              <Checkbox
                label="Table View"
                checked={showTableView}
                onChange={setShowTableView}
              />
              <DataCard
                title={`Break Even Year`}
                body={breakEvenYearDisplay(rentalIncomeBreakEvenPoint)}
                iconVariant={IconType.Calendar}
                style={{ marginTop: '0.5rem' }}
                infoMessage="The year when the rental income breaks even with the total expenses."
              />

              <InvestmentComparisonInput
                annualRoi={annualRoi}
                setAnnualRoi={setAnnualRoi}
                showComparison={showComparison}
                setShowComparison={setShowComparison}
              />
            </>
          ) : (
            <div
              style={{
                height: '100%',
                overflow: 'auto',
              }}
            >
              <DataCard
                title={`Break Even Year`}
                body={breakEvenYearDisplay(rentalIncomeBreakEvenPoint)}
                iconVariant={IconType.Calendar}
                infoTitle="Break Even Year"
                infoMessage="The year when the profit from selling breaks even with the total expenses."
              />

              <RentComparisonInput
                monthlyRent={monthlyRent}
                setMonthlyRent={setMonthlyRent}
                inflationRate={inflationRate}
                setInflationRate={setInflationRate}
                breakEvenYear={breakEvenYear}
                compareRent={compareRent}
                setCompareRent={setCompareRent}
              />
            </div>
          )}
        </Grid>

        <Grid item xs={9} style={{ height: '100%', padding: '1rem' }}>
          {viewType === DashboardViewType.Investor && showTableView ? (
            <SaleChartTable
              estimateOutput={estimateOutput}
              annualRoi={annualRoi}
            />
          ) : (
            <LineChart
              lines={lines}
              xLabel="Year"
              yLabel="Profit at Sale"
              xLabelInterval={1}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SaleChart;
