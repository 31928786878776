import React from 'react';
import { Toolbar as MuiToolbar } from '@mui/material';

interface ToolbarProps {
  children: React.ReactNode;
}

function Toolbar(props: ToolbarProps) {
  return <MuiToolbar {...props}>{props.children}</MuiToolbar>;
}

export default Toolbar;
