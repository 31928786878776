import { useState, useEffect } from 'react';

interface InflatedValue {
  inflatedValue: number;
  year: number;
}

const useInflatedValuesHook = (
  initialValue: number,
  inflationRate: number,
  numberOfYears: number
): InflatedValue[] => {
  const [inflatedValues, setInflatedValues] = useState<
    { inflatedValue: number; year: number }[]
  >([]);

  useEffect(() => {
    const calculateInflatedValues = () => {
      const values: { inflatedValue: number; year: number }[] = [];

      values.push({ inflatedValue: 0, year: 0 });

      for (let year = 1; year < numberOfYears; year++) {
        const inflatedValue =
          initialValue * Math.pow(1 + inflationRate / 100, year - 1);
        values.push({ inflatedValue, year });
      }

      setInflatedValues(values);
    };

    calculateInflatedValues();
  }, [initialValue, inflationRate, numberOfYears]);

  return inflatedValues;
};

export default useInflatedValuesHook;
