import React, { CSSProperties } from 'react';
import { AppBar as MuiAppBar } from '@mui/material';

interface AppBarProps {
  children: React.ReactNode;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  className?: string;
  style?: CSSProperties;
}

function AppBar(props: AppBarProps) {
  return <MuiAppBar {...props}>{props.children}</MuiAppBar>;
}

export default AppBar;
