import React, { useMemo } from 'react';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import LineChart, {
  CoordinateProps,
  LineProps,
} from '../../../components/line-chart/line-chart';
import { ChartBlue } from '../../../utils/constants/color';
import DataCard from '../../../components/data-card/data-card';
import { IconType } from '../../../components/icons/icon';

interface MarketValueChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
}

function MarketValueChart({ estimateOutput, height }: MarketValueChartProps) {
  const marketValueLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: estimateOutput.input.salePrice });

    for (const year of estimateOutput.years) {
      coords.push({ x: year.year, y: year.marketValue });
    }

    return {
      label: 'Market Value',
      key: 'marketValue',
      color: ChartBlue,
      coordinates: coords,
    };
  }, [estimateOutput]);

  return (
    <Box
      style={{
        padding: '16px',
        height: `${height}px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChartHeader
        title="Property Market Value"
        subtitle="The estimated market value of the property over time."
      />

      <Grid container style={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid
          item
          xs={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '50px',
          }}
        >
          <DataCard
            title="Property Appreciation"
            body={`${estimateOutput.input.gains.propertyAppreciationRate}%`}
            iconVariant={IconType.UpArrow}
          />
        </Grid>
        <Grid item xs={9} style={{ padding: '50px' }}>
          <LineChart
            lines={[marketValueLine]}
            xLabel="Year"
            yLabel="Dollars ($)"
            xLabelInterval={1}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MarketValueChart;
