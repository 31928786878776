import React from 'react';
import {
  PieChart as MuiPieChart,
  PieValueType,
  pieArcLabelClasses,
} from '@mui/x-charts';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import Paper from '../paper/paper';

interface PieChartProps {
  data: PieChartSliceProps[];
}

export interface PieChartSliceProps {
  label: string;
  value: number;
  id: number;
  color?: string;
}

function PieChart(props: PieChartProps) {
  return (
    <AutoSizer style={{}}>
      {({ height, width }: Size) => (
        <MuiPieChart
          series={[
            {
              data: props.data,
              arcLabel: (item) => `${item.label}`,
              arcLabelMinAngle: 25,
              paddingAngle: 1,
              innerRadius: 50,
              cornerRadius: 5,
              valueFormatter: (value) =>
                `$${Math.round(value.value).toLocaleString()} ($${Math.round(
                  value.value / 12
                ).toLocaleString()}/mo)`,
            },
          ]}
          slotProps={{
            legend: { hidden: true },
          }}
          height={height}
          width={width}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
            },
          }}
        ></MuiPieChart>
      )}
    </AutoSizer>
  );
}

export default PieChart;
