import React, { CSSProperties } from 'react';
import { Card as MuiCard } from '@mui/material';

interface CardProps {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

function Card(props: CardProps) {
  return <MuiCard {...props}>{props.children}</MuiCard>;
}

export default Card;
