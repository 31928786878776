import React, { useEffect, useRef, useState } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';
import Dashboard, {
  DashboardViewType,
} from '../../features/dashboard/dashboard';

function BuyerDashboard() {
  const topNavBarRef = useRef(null);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);

  // get height of top nav bar
  useEffect(() => {
    if (topNavBarRef.current) {
      setTopNavBarHeight((topNavBarRef.current as HTMLElement).offsetHeight);
    }
  }, []);

  const height = `calc(100vh - ${topNavBarHeight}px)`;

  return (
    <div
      style={{ height: '100vh', width: '100vw' }}
      className="dark1-background-color"
    >
      <TopNavBar ref={topNavBarRef} />
      <Dashboard height={height} viewType={DashboardViewType.Buyer} />
    </div>
  );
}

export default BuyerDashboard;
