import React, { CSSProperties } from 'react';
import { Divider as MuiDivider } from '@mui/material';

interface DividerProps {
  style?: CSSProperties;
}

function Divider(props: DividerProps) {
  return <MuiDivider style={props.style} light />;
}

export default Divider;
