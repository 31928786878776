import React, { createContext } from 'react';

export enum Severity {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
}

interface SnackbarContextProps {
  showMessage: (message: string, severity: Severity) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  showMessage: () => {},
});
