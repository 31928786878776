import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Box from '@mui/material/Box';

type LoadingContainerProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

function LoadingContainer({ isLoading, children }: LoadingContainerProps) {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '100%', maxHeight: '100%' }}
        >
          <CircularProgress style={{ height: '100px', width: '100px' }} />
        </Box>
      ) : (
        children
      )}
    </>
  );
}

export default LoadingContainer;
