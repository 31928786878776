import { Table as MuiTable, TableHead } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import './table.scss';

interface TableProps {
  headers: string[];
  rows: RowProps[];
  className?: string;
}

export interface RowProps {
  cells: string[];
}

function Table({ headers, rows, className }: TableProps) {
  return (
    <TableContainer
      sx={{ flexGrow: 1, border: 'none', height: '100%' }}
      className={`${className} primary-text-color `}
    >
      <MuiTable stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={index}
                align={index === 0 ? 'left' : 'right'}
                className="dark3-background-color"
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.cells.map((cell, index) => (
                <TableCell key={index} align={index === 0 ? 'left' : 'right'}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;
