import React, { CSSProperties, ReactNode } from 'react';
import { Typography as MuiTypography } from '@mui/material';

export enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Subtitle1 = 'subtitle1',
  Subtitle2 = 'subtitle2',
}

interface TypographyProps {
  children: ReactNode;
  variant?: TypographyVariant;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

function Typography(props: TypographyProps) {
  return <MuiTypography {...props}>{props.children}</MuiTypography>;
}

export default Typography;
