import axios from 'axios';
import { EstimateInput } from '../models/EstimateInput';
import { EstimateOutput } from '../models/EstimateOutput';
import { HOUSE_ESTIMATOR_BASE_URL } from '../Constants';
import { InvestmentInput } from '../models/InvestmentInput';
import { InvestmentOutput } from '../models/InvestmentOutput';

const USE_WASM = true;

export async function getBuyerEstimateInput(): Promise<EstimateInput> {
  if (USE_WASM) {
    return JSON.parse(window.getBuyerInput());
  }

  const response = await axios.get<EstimateInput>(
    `${HOUSE_ESTIMATOR_BASE_URL}/input-buyer`
  );
  return response.data;
}

export async function getInvestorEstimateInput(): Promise<EstimateInput> {
  if (USE_WASM) {
    return JSON.parse(window.getInvestorInput());
  }

  const response = await axios.get<EstimateInput>(
    `${HOUSE_ESTIMATOR_BASE_URL}/input-investor`
  );
  return response.data;
}

export async function getEstimateOutput(
  input: EstimateInput
): Promise<EstimateOutput> {
  if (USE_WASM) {
    const inputString = JSON.stringify(input);

    return JSON.parse(window.getEstimateOutput(inputString));
  }

  const response = await axios.post<EstimateOutput>(
    `${HOUSE_ESTIMATOR_BASE_URL}/estimate-home`,
    input
  );
  return response.data;
}

export async function getInvestmentOutput(
  input: InvestmentInput
): Promise<InvestmentOutput> {
  if (USE_WASM) {
    const inputString = JSON.stringify(input);

    return JSON.parse(window.getInvestmentOutput(inputString));
  }

  const response = await axios.post<InvestmentOutput>(
    `${HOUSE_ESTIMATOR_BASE_URL}/investment`,
    input
  );
  return response.data;
}
