import { Tab, Tabs } from '@mui/material';
import React, { Ref, forwardRef } from 'react';

interface TabBarProps {
  tabs: string[];
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const TabBar = forwardRef(function TabBar(
  props: TabBarProps,
  ref?: Ref<HTMLDivElement>
) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    props.setActiveStep(newValue);
  };

  return (
    <div ref={ref}>
      <Tabs
        value={props.activeStep}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
      >
        {props.tabs.map((tab) => (
          <Tab key={tab} label={tab} />
        ))}
      </Tabs>
    </div>
  );
});

export default TabBar;
