import React, { useRef } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';

function SignUpPage() {
  const topNavBarRef = useRef(null);

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        overflowY: 'auto',
      }}
    >
      <TopNavBar ref={topNavBarRef} />
      Coming soon...
    </div>
  );
}

export default SignUpPage;
