import React, { useContext, useMemo, useState } from 'react';
import { EstimateOutput, Year } from '../../../api/models/EstimateOutput';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import DataTile from '../../../components/data-card/data-tile';
import { IconType } from '../../../components/icons/icon';
import Grid from '../../../components/grid/grid';
import { colors } from '../../../Theme';
import './overview-chart.scss';
import {
  Severity,
  SnackbarContext,
} from '../../../components/snackbar/snackbar-context';

interface OverviewChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
  setActiveStep: (step: number) => void;
}

function OverviewChart({
  estimateOutput,
  height,
  setActiveStep,
}: OverviewChartProps) {
  // const { showMessage } = useContext(SnackbarContext);

  // useState(() => {
  //   showMessage(
  //     'Welcome. Change the inputs on the left to start analyzing.',
  //     Severity.Info
  //   );
  // });

  const downPayment: number = useMemo(() => {
    return (
      estimateOutput.years[0].losses.find(
        (loss: { subject: string; amount: number }) =>
          loss.subject === 'downPayment'
      )?.amount || 0
    );
  }, [estimateOutput]);

  const upfrontCosts: number = useMemo(() => {
    return Math.round(
      estimateOutput.years[0].losses.reduce(
        (sum: number, loss: { amount: number }) => sum + loss.amount,
        0
      ) - (downPayment || 0)
    );
  }, [estimateOutput, downPayment]);

  const perMonthCost: number = useMemo(() => {
    return Math.round(
      estimateOutput.years
        .slice(1, 6)
        .reduce(
          (sum: number, year: Year) => sum + year.yearSummary.lossesTotal,
          0
        ) /
        (5 * 12) || 0
    );
  }, [estimateOutput]);

  const perMonthGain: number = useMemo(() => {
    return Math.round(
      estimateOutput.years
        .slice(1, 6)
        .reduce(
          (sum: number, year: Year) => sum + year.yearSummary.gainsTotal,
          0
        ) /
        (5 * 12) || 0
    );
  }, [estimateOutput]);

  const breakEvenYear: string = useMemo(() => {
    for (const year of estimateOutput.years) {
      if (year.sale.netProfit > 0 && year.year !== 0) {
        return 'Year ' + year.year;
      }
    }

    return 'Never';
  }, [estimateOutput]);

  return (
    <Box
      style={{
        height: `calc(${height}px - 1rem)`,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <ChartHeader title="Overview" />

      <Grid
        container
        style={{
          flexGrow: 1,
          overflow: 'hidden',
          height: '100%',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid item xs={3} className="tile-cont">
          <DataTile
            iconVariant={IconType.RequestQuoteIcon}
            iconColor="white"
            title={`$${upfrontCosts.toLocaleString()}`}
            subtitle="Upfront Costs"
            description={`You will pay $${upfrontCosts.toLocaleString()} in upfront costs and $${downPayment.toLocaleString()} as a down payment when purchasing the property.`}
            actionText="Go to graph"
            backgroundColor={colors.Dark1Color}
            iconBackgroundColor="#a6c0ed"
            onClickActionText={() => setActiveStep(2)}
            className="light-text-color"
          />
        </Grid>

        <Grid item xs={3} className="tile-cont">
          <DataTile
            iconVariant={IconType.CreditScoreIcon}
            iconColor="white"
            title={`$${perMonthCost.toLocaleString()}`}
            subtitle="Expenses Per Month"
            description={`Expect to budget an average of $${perMonthCost.toLocaleString()} amount per month for the first 5 years.`}
            actionText="Go to graph"
            backgroundColor={colors.Dark1Color}
            iconBackgroundColor="#a6c0ed"
            onClickActionText={() => setActiveStep(1)}
            className="light-text-color"
          />
        </Grid>

        <Grid item xs={3} className="tile-cont">
          <DataTile
            iconVariant={IconType.Calendar}
            iconColor="white"
            title={breakEvenYear}
            subtitle="Break Even Year"
            description={
              breakEvenYear === 'Never'
                ? 'If you sell the house in the next 50 years, you will never see a profit.'
                : 'Selling the property at ' +
                  breakEvenYear +
                  ' or later will result in a profit.'
            }
            actionText="Go to graph"
            backgroundColor={colors.Dark1Color}
            iconBackgroundColor="#a6c0ed"
            onClickActionText={() => setActiveStep(4)}
            className="light-text-color"
          />
        </Grid>

        <Grid item xs={3} className="tile-cont">
          <DataTile
            iconVariant={IconType.UpArrow}
            iconColor="white"
            title={`$${perMonthGain.toLocaleString()}`}
            subtitle="Returns Per Month"
            description={`Expect to gain an average of $${perMonthGain.toLocaleString()} amount per month for the first 5 years. Appreciation, tax savings, and equity contribute to these returns.`}
            actionText="Go to graph"
            backgroundColor={colors.Dark1Color}
            iconBackgroundColor="#a6c0ed"
            onClickActionText={() => setActiveStep(3)}
            className="light-text-color"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OverviewChart;
