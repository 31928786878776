import React, { useMemo } from 'react';
import LineChart, {
  CoordinateProps,
  LineProps,
} from '../../../components/line-chart/line-chart';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import {
  ChartGreen,
  ChartOrange,
  ChartRed,
} from '../../../utils/constants/color';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import { DashboardViewType } from '../dashboard';

interface OutOfPocketChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
  viewType: DashboardViewType;
}

function OutOfPocketChart({
  estimateOutput,
  height,
  viewType,
}: OutOfPocketChartProps) {
  const yearlyOutOfPocketLine: LineProps = useMemo(() => {
    const yearlyOutOfPocketCoords: CoordinateProps[] = [];

    yearlyOutOfPocketCoords.push({ x: 0, y: 0 });

    for (let i = 0; i < estimateOutput.years.length; i++) {
      const year = estimateOutput.years[i];

      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      const net = rentalIncome - year.yearSummary.lossesTotal;

      const contribution = net < 0 ? net * -1 : 0;

      yearlyOutOfPocketCoords.push({ x: year.year, y: contribution });
    }

    return {
      label: 'Out of Pocket Costs',
      key: 'outOfPocketCosts',
      color: ChartRed,
      coordinates: yearlyOutOfPocketCoords,
    };
  }, [estimateOutput]);

  const yearlyRentalIncomeLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      coords.push({ x: year.year, y: rentalIncome });
    }

    return {
      label: 'Rental Income',
      key: 'rentalIncome',
      color: ChartGreen,
      coordinates: coords,
    };
  }, [estimateOutput]);

  const yearlyExpenseLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      coords.push({ x: year.year, y: year.yearSummary.lossesTotal });
    }

    return {
      label: 'Total Expenses',
      key: 'totalExpenses',
      color: ChartOrange,
      coordinates: coords,
    };
  }, [estimateOutput]);

  const lines = [
    yearlyExpenseLine,
    yearlyOutOfPocketLine,
    yearlyRentalIncomeLine,
  ];

  return (
    <Box
      style={{
        padding: '16px',
        height: `${height}px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChartHeader
        title="Out of Pocket Costs"
        subtitle="Displays rental income per year, total expenses per year, and the out of pocket cost each year. Out of pocket cost is what you will need to pay every year (total expenses minus rental income)."
      />

      <Grid container style={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid item xs={12}>
          <LineChart lines={lines} xLabel="Year" yLabel="Dollars ($)" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OutOfPocketChart;
