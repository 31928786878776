import React, { useEffect, useMemo, useState } from 'react';
import { EstimateOutput, Year } from '../api/models/EstimateOutput';
import {
  CoordinateProps,
  LineProps,
} from '../components/line-chart/line-chart';
import {
  InvestmentInput,
  InvestmentInputYear,
} from '../api/models/InvestmentInput';
import { useInvestmentOutputMutation } from '../api/mutations/EstimateOutput';
import { ChartBlue } from './constants/color';
import { InvestmentOutput } from '../api/models/InvestmentOutput';

export const useInvestmentLineHook = (
  estimatedOutput: EstimateOutput,
  annualRoi: number
): LineProps => {
  // build input
  const input: InvestmentInput = useMemo(() => {
    let years: InvestmentInputYear[] = [];

    for (const year of estimatedOutput.years) {
      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      const net = rentalIncome - year.yearSummary.lossesTotal;

      years.push({
        year: year.year,
        newPrinciple: net < 0 ? net * -1 : 0,
      });
    }

    return {
      annualRoi: annualRoi,
      years: years,
    };
  }, [estimatedOutput, annualRoi]);

  // get output
  const investmentOutputMutation = useInvestmentOutputMutation();

  const [coordinates, setCoordinates] = useState<CoordinateProps[]>([]);

  useEffect(() => {
    if (input.annualRoi === null) {
      input.annualRoi = 0;
    }

    if (input.years.length !== 0) {
      investmentOutputMutation.mutateAsync(input, {
        onSuccess: (output) => {
          let coords: CoordinateProps[] = [];

          coords.push({ x: 0, y: 0 });

          for (const year of output.years) {
            coords.push({ x: year.year, y: year.value });
          }

          setCoordinates(coords);
        },
      });
    }
  }, [input]);

  return {
    label: 'Traditional Investment',
    key: 'traditionalInvestment',
    color: ChartBlue,
    coordinates: coordinates,
  };
};

export const useInvestmentHook = (
  estimatedOutput: EstimateOutput,
  annualRoi: number
): InvestmentOutput => {
  // build input
  const input: InvestmentInput = useMemo(() => {
    let years: InvestmentInputYear[] = [];

    for (const year of estimatedOutput.years) {
      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      const net = rentalIncome - year.yearSummary.lossesTotal;

      years.push({
        year: year.year,
        newPrinciple: net < 0 ? net * -1 : 0,
      });
    }

    return {
      annualRoi: annualRoi,
      years: years,
    };
  }, [estimatedOutput, annualRoi]);

  // get output
  const investmentOutputMutation = useInvestmentOutputMutation();

  const [output, setOutput] = useState<InvestmentOutput>({ years: [] });

  useEffect(() => {
    if (input.annualRoi === null) {
      input.annualRoi = 0;
    }

    if (input.years.length !== 0) {
      investmentOutputMutation.mutateAsync(input, {
        onSuccess: (res) => {
          setOutput(res);
        },
      });
    }
  }, [input]);

  return output;
};
